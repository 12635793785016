<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "BusinessRuleActions.NewBusinessRuleAction",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        @methodSave="onSubmit('0', 'btn-save')"
        @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
        @clear="onClear()"
        :isNewRecord="true"
      />
    </div>
  </div>
  <form class="form-businessruleactionsendnotif-new pe-2 ps-2" novalidate>
    <div v-if="errors.length > 0" class="alert alert-warning">
      <ul class="mb-0">
        <li v-for="error in errors" v-bind:key="error">
          {{ error }}
        </li>
      </ul>
    </div>
    <div v-if="isSuccess" class="alert alert-success">
      {{
        $t(
          "Messages.SuccessMessage",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
    <div class="row row-cols-1">
      <div class="col col-md-4">
        <label for="Name" class="form-label required"
          >{{
            $t(
              "BusinessRuleActions.Name",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          @keyup="nameKeyup()"
          v-model="businessRuleActionData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-4">
        <label for="FormulaName" class="form-label required">{{
          $t(
            "BusinessRuleActions.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="text"
          class="form-control text-uppercase"
          id="FormulaName"
          @keyup="formulaNameKeyup()"
          v-model="businessRuleActionData.formulaName"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-4">
        <label for="BusinessActionType" class="form-label"
          >{{
            $t(
              "BusinessRuleActions.BusinessActionType",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          disabled
          id="BusinessActionType"
          v-model="businessRuleActionType"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
    </div>
    <div class="row row-cols-1">
      <div class="col col-md-12 mt-3">
        <label for="Description" class="form-label">{{
          $t(
            "BusinessRuleActions.Description",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="Description"
          class="form-control"
          id="Description"
          v-model="businessRuleActionData.description"
          :spellcheck="this.$isTextSpellCheck"
          rows="2"
        ></textarea>
      </div>
      <div class="col col-md-12 mt-3">
        <label for="ResponseText" class="form-label">{{
          $t(
            "BusinessRuleActions.ResponseText",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="ResponseText"
          class="form-control"
          id="ResponseText"
          v-model="businessRuleActionData.responseText"
          :spellcheck="this.$isTextSpellCheck"
          rows="1"
        ></textarea>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div style="display: none" class="form-check isForcingSave">
        <label for="isForcingSave" class="form-label">{{
          $t(
            "BusinessRuleActions.IsForcingSave",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="isForcingSave"
          v-model="businessRuleActionData.isForcingSave"
        />
      </div>
      <div class="form-check isRunOnceForEachRecord">
        <label for="IsRunOnceForEachRecord" class="form-label">{{
          $t(
            "BusinessRuleActions.IsRunOnceForEachRecord",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsRunOnceForEachRecord"
          v-model="businessRuleActionData.isRunOnceForEachRecord"
        />
      </div>
      <div class="form-check">
        <label for="IsSynchAction" class="form-label">{{
          $t(
            "BusinessRuleActions.IsSynchAction",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsSynchAction"
          v-model="businessRuleActionData.isSynchAction"
        />
      </div>
      <div class="form-check">
        <label for="IsResponseReturnToClient" class="form-label">{{
          $t(
            "BusinessRuleActions.IsResponseReturnToClient",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsResponseReturnToClient"
          v-model="businessRuleActionData.isResponseReturnToClient"
        />
      </div>
      <div class="form-check httpRequestAdress" style="display: none">
        <label for="DownloadResponseAsDocument" class="form-label">{{
          $t(
            "BusinessRuleActions.DownloadResponseAsDocument",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="DownloadResponseAsDocument"
          v-model="businessRuleActionData.DownloadResponseAsDocument"
        />
      </div>
      <div class="form-check">
        <label for="IsActive" class="form-label">{{
          $t(
            "BusinessRuleActions.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsActive"
          v-model="businessRuleActionData.isActive"
        />
      </div>
      <div
        style="display: none"
        class="form-check isNotSendEmailIfThereIsNoRecord"
      >
        <label for="isNotSendEmailIfThereIsNoRecord" class="form-label">{{
          $t(
            "BusinessRuleActions.IsNotSendEmailIfThereIsNoRecord",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsNotSendEmailIfThereIsNoRecord"
          v-model="businessRuleActionData.isNotSendEmailIfThereIsNoRecord"
        />
      </div>
      <div style="display: none" class="form-check isRunSeparatelyForEachUser">
        <label for="isRunSeparatelyForEachUser" class="form-label">{{
          $t(
            "BusinessRuleActions.IsRunSeparatelyForEachUser",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsRunSeparatelyForEachUser"
          v-model="businessRuleActionData.isRunSeparatelyForEachUser"
        />
      </div>
    </div>
    <div class="row row-cols-12 mt-3 sendNotification">
      <div class="col col-md-12">
        <label for="NotificationType" class="form-label required">{{
          $t(
            "BusinessRuleActions.NotificationType",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :allowEmpty="false"
          :selectedData="selectedNotificationType"
          :data="notificationTypeData"
          @onChange="onChangeForNotificationType"
        />
      </div>
      <div class="row row-cols-12 mt-3 emailNotTab">
        <div class="col col-md-6">
          <label for="NotificationSettings" class="form-label required">{{
            $t(
              "BusinessRuleActions.NotificationSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <FormSelect
            type="SelectWithRemoteDataAndInternalSearch"
            :allowEmpty="false"
            :selectedData="selectedNotifierSetting"
            :isParameters="true"
            :resetSelect="selectsReset"
            :isGatewayRequest="true"
            :requestUrl="
              String.format(
                '/Lcdp-SummaryNotifierSettings?type={0}',
                this.notificationType
              )
            "
            @onChange="onChangeForNotifierSettings"
          />
        </div>
        <div class="col col-md-6">
          <label for="SelectMessageTemplate" class="form-label required">{{
            $t(
              "BusinessRuleActions.SelectMessageTemplate",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <FormSelect
            type="SelectWithRemoteDataAndInternalSearch"
            :allowEmpty="false"
            :selectedData="selectedMessageTemplate"
            :isParameters="true"
            :resetSelect="selectsReset"
            :isGatewayRequest="true"
            :requestUrl="
              String.format(
                '/Lcdp-SummaryMessageTemplates?type={0}',
                this.notificationType
              )
            "
            @onChange="onChangeForMessageTemplate"
          />
        </div>
      </div>
      <div style="display: none" class="row row-cols-12 mt-3 smsNotTab">
        <div class="col col-md-6">
          <label for="NotificationSettings" class="form-label required">{{
            $t(
              "BusinessRuleActions.NotificationSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <FormSelect
            type="SelectWithRemoteDataAndInternalSearch"
            :allowEmpty="false"
            :selectedData="selectedNotifierSetting"
            :isParameters="true"
            :resetSelect="selectsReset"
            :isGatewayRequest="true"
            :requestUrl="
              String.format(
                '/Lcdp-SummaryNotifierSettings?type={0}',
                this.notificationType
              )
            "
            @onChange="onChangeForNotifierSettings"
          />
        </div>
        <div class="col col-md-6">
          <label for="SelectMessageTemplate" class="form-label required">{{
            $t(
              "BusinessRuleActions.SelectMessageTemplate",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <FormSelect
            type="SelectWithRemoteDataAndInternalSearch"
            :allowEmpty="false"
            :resetSelect="selectsReset"
            :selectedData="selectedMessageTemplate"
            :isParameters="true"
            :isGatewayRequest="true"
            :requestUrl="
              String.format(
                '/Lcdp-SummaryMessageTemplates?type={0}',
                this.notificationType
              )
            "
            @onChange="onChangeForMessageTemplate"
          />
        </div>
      </div>
      <div style="display: none" class="row row-cols-12 mt-3 systemNotTab">
        <!-- <div class="col col-md-6">
          <label for="NotificationSettings" class="form-label required">{{
            $t(
              "BusinessRuleActions.NotificationSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <FormSelect
            type="SelectWithRemoteDataAndInternalSearch"
            :allowEmpty="false"
            :selectedData="selectedNotifierSetting"
            :isParameters="true"
            :resetSelect="selectsReset"
            :isGatewayRequest="true"
            :requestUrl="
              String.format(
                '/Lcdp-SummaryNotifierSettings?type={0}',
                this.notificationType
              )
            "
            @onChange="onChangeForNotifierSettings"
          />
        </div> -->
        <div class="col col-md-6">
          <label for="SelectMessageTemplate" class="form-label required">{{
            $t(
              "BusinessRuleActions.SelectMessageTemplate",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <FormSelect
            type="SelectWithRemoteDataAndInternalSearch"
            :allowEmpty="false"
            :resetSelect="selectsReset"
            :selectedData="selectedMessageTemplate"
            :isParameters="true"
            :isGatewayRequest="true"
            :requestUrl="
              String.format(
                '/Lcdp-SummaryMessageTemplates?type={0}',
                this.notificationType
              )
            "
            @onChange="onChangeForMessageTemplate"
          />
        </div>
      </div>
    </div>
    <div class="card mt-4">
      <div class="card-body">
        <h3 class="card-title">
          <i class="bi bi-sliders"></i>
          {{
            $t(
              "Fields.CustomSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </h3>
        <div class="col col-md-12 mt-3 sendByEmail">
          <div class="form-check">
            <label
              for="SendByEmailOfUserWhoUpdatesRegistration"
              class="form-label"
              >{{
                $t(
                  "BusinessRuleActions.SendByEmailOfUserWhoUpdatesRegistration",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label
            >
            <input
              class="form-check-input"
              type="checkbox"
              id="SendByEmailOfUserWhoUpdatesRegistration"
              v-model="businessRuleActionData.isSendWithCurrentUserMail"
            />
          </div>
        </div>
        <div class="row row-cols-1 sendNotification email">
          <div class="col col-md-6">
            <label for="Subject" class="form-label required"
              >{{
                $t(
                  "BusinessRuleActions.Subject",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="text"
              class="form-control"
              id="Subject"
              v-model="businessRuleActionData.subject"
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
              required
            />
          </div>
          <div class="col col-md-6 notificationPlugin">
            <label for="notificationPlugin" class="form-label">{{
              $t(
                "BusinessRuleActions.SelectTemplateForNotificationPlugin",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <FormSelect
              type="SelectWithRemoteDataAndInternalSearch"
              :isMultiple="true"
              @onMultipleSelectChange="onChangeFormTemplatesForBusinessRule"
              @onMultipleSelectChangeReturnValues="
                onChangeFormTemplateNamesForBusinessRule
              "
              :isParameters="true"
              :isGatewayRequest="true"
              :requestUrl="
                String.format(
                  '/Lcdp-SummaryFormTemplates?coId={0}',
                  this.$route.params.customObjectId
                )
              "
            />
          </div>
          <div class="col col-md-12 mt-2 attachment">
            <label for="attachment" class="form-label">{{
              $t(
                "BusinessRuleActions.SelectAttachment",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <FormSelect
              type="SelectWithRemoteDataAndInternalSearch"
              :isMultiple="true"
              @onMultipleSelectChange="onChangeForFieldsByTypeFile"
              @onMultipleSelectChangeReturnValues="
                onChangeForFieldsByTypeFileNames
              "
              :isParameters="true"
              :isGatewayRequest="true"
              :requestUrl="
                String.format(
                  '/Lcdp-SummaryGetRelatedAttachmentFieldsByCustomObject?parentId={0}',
                  this.$route.params.customObjectId
                )
              "
            />
          </div>
        </div>
        <ul class="nav nav-tabs mb-2 mt-2 tabs sendNotification" role="tablist">
          <li class="nav-item toWhoTab" role="presentation">
            <button
              class="nav-link active"
              id="pills-to-who-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-to-who"
              type="button"
              role="tab"
              aria-selected="true"
            >
              {{
                $t(
                  "BusinessRuleActions.ToWho",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </li>
          <li class="nav-item ccTab" role="presentation">
            <button
              class="nav-link"
              id="pills-cc-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-cc"
              type="button"
              role="tab"
              aria-selected="false"
            >
              {{
                $t(
                  "BusinessRuleActions.CC",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </li>
          <li class="nav-item bccTab" role="presentation">
            <button
              class="nav-link"
              id="pills-cc-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-bcc"
              type="button"
              role="tab"
              aria-selected="false"
            >
              {{
                $t(
                  "BusinessRuleActions.BCC",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </li>
        </ul>
        <div class="tab-content tabs sendNotification">
          <div
            class="tab-pane fade active show"
            id="pills-to-who"
            role="tabpanel"
          >
            <div class="row row-cols-12 mt-3 ToTab">
              <div class="col col-md-12">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceType"
                  :data="sourceTypeData"
                  @onChange="onChangeForSourceTypeToWho"
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 source">
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedUser"
                  :data="userData"
                  @onChange="onChangeForSourceSelectForTo"
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 user">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :allowEmpty="false"
                  @onMultipleSelectChange="onChangeForUserTO"
                  @onMultipleSelectChangeReturnValues="onChangeForUserTONames"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=User&filterType=User&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 userGroup">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForUserGroupTO"
                  :allowEmpty="false"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForUserGroupTONames
                  "
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup&filterType=UserGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                style="display: none"
                class="col col-md-12 mt-3 permissionGroup"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForPermissionGroupTO"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForPermissionGroupTONames
                  "
                  :isParameters="true"
                  :allowEmpty="false"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup&filterType=PermissionGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                style="display: none"
                class="col col-md-12 mt-3 mailToFromField"
              >
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForSourceFromFieldTO"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForSourceFromFieldTONames
                  "
                  :isParameters="true"
                  :allowEmpty="false"
                  :resetSelect="selectsReset"
                  :isGatewayRequest="true"
                  :requestUrl="
                    businessRuleActionData.NotifierTypeId == '2'
                      ? String.format(
                          '/Lcdp-SummaryGetRelatedPhoneFieldsByCustomObject?parentId={0}',
                          this.$route.params.customObjectId
                        )
                      : String.format(
                          '/Lcdp-SummaryGetRelatedEmailFieldsByCustomObject?parentId={0}',
                          this.$route.params.customObjectId
                        )
                  "
                  openDirection="top"
                />
              </div>
              <div class="col col-md-12 mt-3 mailToType1">
                <label for="Source" class="form-label required"
                  >{{
                    $t(
                      "BusinessRuleActions.Source",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="RecipientsToText"
                  v-model="businessRuleActionData.RecipientsToText"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
            </div>
            <div
              style="display: none"
              class="row row-cols-12 mt-3 systemNotificationTab"
            >
              <div class="col col-md-12">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceType3"
                  :data="sourceTypeData3"
                  @onChange="onChangeForSourceTypeToWhoSystemNotif"
                />
              </div>
              <div class="col col-md-12 mt-3 source5">
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedUser"
                  :data="userData"
                  @onChange="onChangeForSourceSelect5"
                  openDirection="top"
                />
              </div>
              <div class="col col-md-12 mt-3 user5">
                <!-- <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :allowEmpty="false"
                  @onMultipleSelectChange="onChangeForUser"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=User&filterType=User&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                /> -->
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :allowEmpty="false"
                  @onMultipleSelectChange="onChangeForUserTO"
                  @onMultipleSelectChangeReturnValues="onChangeForUserTONames"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=User&filterType=User&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 userGroup5">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :allowEmpty="false"
                  @onMultipleSelectChange="onChangeForUserGroup"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup&filterType=UserGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                style="display: none"
                class="col col-md-12 mt-3 permissionGroup5"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :allowEmpty="false"
                  @onMultipleSelectChange="onChangeForPermissionGroup"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup&filterType=PermissionGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                style="display: none"
                class="col col-md-12 mt-3 fieldsByType5"
              >
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :allowEmpty="false"
                  @onMultipleSelectChange="onChangeForUserGroup"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForSourceFromFieldTONames
                  "
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryFieldsByType?parentId={0}&type={1}',
                      this.$route.params.customObjectId,
                      this.fieldsType
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 sourceText5">
                <label for="Source" class="form-label required"
                  >{{
                    $t(
                      "BusinessRuleActions.Source",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="Source"
                  v-model="businessRuleActionData.source"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
            </div>
            <div
              style="display: none"
              class="row row-cols-12 mt-3 timeTriggeredTab"
            >
              <div class="col col-md-12">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceType"
                  :data="sourceTypeData4"
                  @onChange="onChangeForSourceTypeToWhoTimeTriggered"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 source6">
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedUser"
                  :data="userData"
                  @onChange="onChangeForSourceSelect6"
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 user6">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :allowEmpty="false"
                  @onMultipleSelectChange="onChangeForUser"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=User&filterType=User&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 userGroup6">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :allowEmpty="false"
                  @onMultipleSelectChange="onChangeForUserGroup"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup&filterType=UserGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                style="display: none"
                class="col col-md-12 mt-3 permissionGroup6"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :allowEmpty="false"
                  @onMultipleSelectChange="onChangeForPermissionGroup"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup&filterType=PermissionGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div class="col col-md-12 mt-3 sourceText6">
                <label for="Source" class="form-label required"
                  >{{
                    $t(
                      "BusinessRuleActions.EmailAdressInformation",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="Source"
                  v-model="businessRuleActionData.source"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-cc" role="tabpanel">
            <div class="row row-cols-12 mt-3">
              <div class="col col-md-12 otherCc">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceType"
                  :data="sourceTypeData2"
                  @onChange="onChangeForSourceTypeCc"
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 timeTriggeredCc">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceType"
                  :data="sourceTypeData5"
                  @onChange="onChangeForSourceTypeTimeTriggeredCc"
                  openDirection="top"
                />
              </div>
              <div class="col col-md-12 mt-3 mailCCType1">
                <label for="source" class="form-label"
                  >{{
                    $t(
                      "BusinessRuleActions.Source",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="RecipientsCCText"
                  v-model="businessRuleActionData.RecipientsCCText"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 source2">
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedUser"
                  :data="userData"
                  @onChange="onChangeForSourceSelectForCC"
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 user2">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForUserCC"
                  @onMultipleSelectChangeReturnValues="onChangeForUserCCNames"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=User&filterType=User&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 userGroup2">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForUserGroupCC"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForUserGroupCCNames
                  "
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup&filterType=UserGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                style="display: none"
                class="col col-md-12 mt-3 permissionGroup2"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForPermissionGroupCC"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForPermissionGroupCCNames
                  "
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup&filterType=PermissionGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                style="display: none"
                class="col col-md-12 mt-3 fieldsByType2"
              >
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :allowEmpty="false"
                  @onMultipleSelectChange="onChangeForSourceFromFieldCC"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForSourceFromFieldCCNames
                  "
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryGetRelatedEmailFieldsByCustomObject?parentId={0}',
                      this.$route.params.customObjectId
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 related2">
                <label for="source" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :isMultiple="true"
                  :data="relatedSource"
                  @onMultipleSelectChange="onChangeForRelatedCC"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForRelatedCCNames
                  "
                  openDirection="top"
                />
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-bcc" role="tabpanel">
            <div class="row row-cols-12 mt-3">
              <div class="col col-md-12 otherBcc">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceType"
                  :data="sourceTypeData2"
                  @onChange="onChangeForSourceTypeBcc"
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 timeTriggeredBcc">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceType"
                  :data="sourceTypeData5"
                  @onChange="onChangeForSourceTypeTimeTriggeredBcc"
                  openDirection="top"
                />
              </div>
              <div class="col col-md-12 mt-3 mailBCCType1">
                <label for="source" class="form-label"
                  >{{
                    $t(
                      "BusinessRuleActions.Source",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="RecipientsBCCText"
                  v-model="businessRuleActionData.RecipientsBCCText"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 source3">
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedUser"
                  :data="userData"
                  @onChange="onChangeForSourceSelectForBCC"
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 user3">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForUserBCC"
                  @onMultipleSelectChangeReturnValues="onChangeForUserBCCNames"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=User&filterType=User&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 userGroup3">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForUserGroupBCC"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForUserGroupBCCNames
                  "
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup&filterType=UserGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                style="display: none"
                class="col col-md-12 mt-3 permissionGroup3"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForPermissionGroupBCC"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForPermissionGroupBCCNames
                  "
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup&filterType=PermissionGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                style="display: none"
                class="col col-md-12 mt-3 fieldsByType3"
              >
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForSourceFromFieldBCC"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForSourceFromFieldBCCNames
                  "
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryGetRelatedEmailFieldsByCustomObject?parentId={0}',
                      this.$route.params.customObjectId
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 related3">
                <label for="source" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :isMultiple="true"
                  :data="relatedSource"
                  @onMultipleSelectChange="onChangeForRelatedBCC"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForRelatedBCCNames
                  "
                  openDirection="top"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from "jquery";
export default {
  name: "CustomObjectNewBusinessRuleActionSendNotification",
  data() {
    return {
      selectsReset: false,
      notificationTypeData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.Email",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.SMS",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.SystemNotification",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedNotificationType: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.Email",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedNotifierSetting: [],
      selectedMessageTemplate: [],
      organizationalUnitData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.User",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.UserGroup",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.PermissionGroup",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      sourceTypeData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.FromTheFields",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      sourceTypeData2: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.FromTheFields",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 4,
          value: this.$t(
            "BusinessRuleActions.Related",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      sourceTypeData3: [
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.FromTheFields",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      sourceTypeData4: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      sourceTypeData5: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.Related",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedSourceType: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedSourceType3: [
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      relatedSource: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FirstManager",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.SecondManager",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.ThirdManager",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 4,
          value: this.$t(
            "BusinessRuleActions.Teammate",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      userData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.User",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.UserGroup",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.PermissionGroup",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedUser: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.User",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      createRecordFieldFormulasForEdit: [],
      selectedFieldTO: [],
      businessRuleActionData: {
        businessRulePublicId: this.$route.params.businessRuleId,
        customObjectPublicId: this.$route.params.customObjectId,
        name: "",
        description: "",
        responseText: "",
        isActive: true,
        isSynchAction: false,
        downloadResponseAsDocument: false,
        createRecordRequest: this.$route.params.pageType,
        isRunOnceForEachRecord: false,
        fieldValueFormulas: [],
        NotifierTypeId: 1,
        RecipientsToInfoType: 1,
        RecipientsCCInfoType: 1,
        RecipientsBCCInfoType: 1,
        BusinessRuleActionType: "SendNotification",
      },
      businessRuleActionType: "",
      businessRuleTriggerTypeId: null,
      notificationType: 1,
      AttachementId: null,
      sourceSelect: "User",
      userList: "",
      userGroupList: "",
      permissionGroupList: "",
      fieldsType: "Email|FormulaOrganizationalUnit",
      businessActionObject: [],
      detailForwardOrEditForwardType: false,
      isSuccess: false,
      multipleSelect: this.$t(
        "Fields.MultipleSelect",
        {},
        { locale: this.$store.state.activeLang }
      ),
      select: this.$t(
        "Fields.Select",
        {},
        { locale: this.$store.state.activeLang }
      ),
      table: this.$t(
        "Fields.Table",
        {},
        { locale: this.$store.state.activeLang }
      ),
    };
  },
  methods: {
    //hide-showların başladığı alan
    nameKeyup() {
      if (String.isNullOrWhiteSpace(this.businessRuleActionData.name)) {
        this.businessRuleActionData.formulaName = "";
        return;
      }
      this.businessRuleActionData.formulaName = String.replaceAlphanumeric(
        this.businessRuleActionData.name,
        "_"
      ).toUpperCase();
    },
    formulaNameKeyup() {
      this.businessRuleActionData.formulaName = String.replaceAlphanumeric(
        this.businessRuleActionData.formulaName,
        "_"
      ).toUpperCase();
    },
    onChangeForSourceTypeToWho: function (selected) {
      this.businessRuleActionData.RecipientsToInfoType = selected.key;
      if (selected.key == "1") {
        $(".source").hide();
        $(".mailToType1").show();
        $(".user").hide();
        $(".userGroup").hide();
        $(".permissionGroup").hide();
        $(".mailToFromField").hide();
        $(".related").hide();
      } else if (selected.key == "2") {
        $(".source").show();
        $(".user").show();
        $(".mailToType1").hide();
        $(".mailToFromField").hide();
        $(".related").hide();
      } else if (selected.key == "3") {
        $(".source").hide();
        $(".user").hide();
        $(".userGroup").hide();
        $(".permissionGroup").hide();
        $(".mailToType1").hide();
        $(".mailToFromField").show();
        $(".related").hide();
      }
    },
    onChangeForSourceTypeCc: function (selected) {
      this.businessRuleActionData.RecipientsCCInfoType = selected.key;
      if (selected.key == "1") {
        $(".source2").hide();
        $(".mailCCType1").show();
        $(".user2").hide();
        $(".userGroup2").hide();
        $(".permissionGroup2").hide();
        $(".fieldsByType2").hide();
        $(".related2").hide();
      } else if (selected.key == "2") {
        $(".source2").show();
        $(".user2").show();
        $(".mailCCType1").hide();
        $(".fieldsByType2").hide();
        $(".related2").hide();
      } else if (selected.key == "3") {
        $(".source2").hide();
        $(".user2").hide();
        $(".userGroup2").hide();
        $(".permissionGroup2").hide();
        $(".mailCCType1").hide();
        $(".fieldsByType2").show();
        $(".related2").hide();
      } else if (selected.key == "4") {
        $(".source2").hide();
        $(".user2").hide();
        $(".userGroup2").hide();
        $(".permissionGroup2").hide();
        $(".mailCCType1").hide();
        $(".fieldsByType2").hide();
        $(".related2").show();
      }
    },
    onChangeForSourceTypeTimeTriggeredCc: function (selected) {
      $(".related").hide();
      $(".related3").hide();
      if (selected.key == "1") {
        $(".source2").hide();
        $(".mailCCType1").show();
        $(".user2").hide();
        $(".userGroup2").hide();
        $(".permissionGroup2").hide();
        $(".related2").hide();
      } else if (selected.key == "2") {
        $(".source2").show();
        $(".user2").show();
        $(".mailCCType1").hide();
        $(".related2").hide();
      } else if (selected.key == "3") {
        $(".source2").hide();
        $(".user2").hide();
        $(".userGroup2").hide();
        $(".permissionGroup2").hide();
        $(".mailCCType1").hide();
        $(".related2").show();
      }
    },
    onChangeForSourceTypeBcc: function (selected) {
      this.businessRuleActionData.RecipientsBCCInfoType = selected.key;
      if (selected.key == "1") {
        $(".source3").hide();
        $(".mailBCCType1").show();
        $(".user3").hide();
        $(".userGroup3").hide();
        $(".permissionGroup3").hide();
        $(".fieldsByType3").hide();
        $(".related3").hide();
      } else if (selected.key == "2") {
        $(".source3").show();
        $(".user3").show();
        $(".mailBCCType1").hide();
        $(".fieldsByType3").hide();
        $(".related3").hide();
      } else if (selected.key == "3") {
        $(".source3").hide();
        $(".user3").hide();
        $(".userGroup3").hide();
        $(".permissionGroup3").hide();
        $(".mailBCCType1").hide();
        $(".fieldsByType3").show();
        $(".related3").hide();
      } else if (selected.key == "4") {
        $(".source3").hide();
        $(".user3").hide();
        $(".userGroup3").hide();
        $(".permissionGroup3").hide();
        $(".mailBCCType1").hide();
        $(".fieldsByType3").hide();
        $(".related3").show();
      }
    },
    onChangeForSourceTypeTimeTriggeredBcc: function (selected) {
      if (selected.key == "1") {
        $(".source3").hide();
        $(".mailBCCType1").show();
        $(".user3").hide();
        $(".userGroup3").hide();
        $(".permissionGroup3").hide();
        $(".related3").hide();
        $(".related").hide();
        $(".related2").hide();
      } else if (selected.key == "2") {
        $(".source3").show();
        $(".user3").show();
        $(".mailBCCType1").hide();
        $(".related3").hide();
        $(".related").hide();
        $(".related2").hide();
      } else if (selected.key == "3") {
        $(".source3").hide();
        $(".user3").hide();
        $(".userGroup3").hide();
        $(".permissionGroup3").hide();
        $(".mailBCCType1").hide();
        $(".related3").show();
        $(".related").hide();
        $(".related2").hide();
      }
    },
    onChangeForSourceTypeSms: function (selected) {
      this.businessRuleActionData.RecipientsToInfoType = selected.key;
      $(".fieldsByType3").hide();
      $(".related3").hide();
      $(".fieldsByType2").hide();
      $(".related2").hide();
      $(".mailToFromField").hide();
      $(".fieldsByType5").hide();
      if (selected.key == "1") {
        $(".source4").hide();
        $(".sourceTextSms").show();
        $(".userSms").hide();
        $(".userGroupSms").hide();
        $(".permissionGroupSms").hide();
        $(".fieldsByType4").hide();
      } else if (selected.key == "2") {
        $(".source4").show();
        $(".userSms").show();
        $(".sourceTextSms").hide();
        $(".fieldsByType4").hide();
      } else if (selected.key == "3") {
        $(".source4").hide();
        $(".userSms").hide();
        $(".userGroupSms").hide();
        $(".permissionGroupSms").hide();
        $(".sourceTextSms").hide();
        $(".fieldsByType4").show();
      } else if (selected.key == "4") {
        $(".source4").hide();
        $(".userSms").hide();
        $(".userGroupSms").hide();
        $(".permissionGroupSms").hide();
        $(".sourceTextSms").hide();
        $(".fieldsByType4").hide();
      }
    },
    onChangeForSourceTypeToWhoSystemNotif: function (selected) {
      this.businessRuleActionData.RecipientsToInfoType = selected.key;
      $(".fieldsByType3").hide();
      $(".related3").hide();
      $(".fieldsByType2").hide();
      $(".related2").hide();
      $(".mailToFromField").hide();
      $(".fieldsByType4").hide();
      if (selected.key == "1") {
        $(".source5").hide();
        $(".sourceText5").show();
        $(".user5").hide();
        $(".userGroup5").hide();
        $(".permissionGroup5").hide();
      } else if (selected.key == "2") {
        $(".source5").show();
        $(".user5").show();
        $(".sourceText5").hide();
        $(".fieldsByType5").hide();
      } else if (selected.key == "3") {
        $(".source5").hide();
        $(".user5").hide();
        $(".userGroup5").hide();
        $(".permissionGroup5").hide();
        $(".sourceText5").hide();
        $(".fieldsByType5").show();
      } else if (selected.key == "4") {
        $(".source5").hide();
        $(".user5").hide();
        $(".userGroup5").hide();
        $(".permissionGroup5").hide();
        $(".sourceText5").hide();
        $(".fieldsByType5").hide();
      }
    },
    onChangeForSourceTypeToWhoTimeTriggered: function (selected) {
      $(".related3").hide();
      $(".related2").hide();
      if (selected.key == "1") {
        $(".source6").hide();
        $(".sourceText6").show();
        $(".user6").hide();
        $(".userGroup6").hide();
        $(".permissionGroup6").hide();
      } else if (selected.key == "2") {
        $(".source6").show();
        $(".user6").show();
        $(".sourceText6").hide();
      }
    },
    onChangeForSourceSelectForTo: function (selected) {
      this.businessRuleActionData.RecipientsToOrganizationalUnit = selected.key;
      if (selected.key == "1") {
        this.sourceSelect = "User";
        $(".user").show();
        $(".userGroup").hide();
        $(".permissionGroup").hide();
        this.businessRuleActionData.RecipientsToOrgUnitUser = selected.value;
      } else if (selected.key == "2") {
        this.sourceSelect = "UserGorup";
        $(".user").hide();
        $(".userGroup").show();
        $(".permissionGroup").hide();
        selected.value;
      } else if (selected.key == "3") {
        this.sourceSelect = "PermissionGroup";
        $(".user").hide();
        $(".userGroup").hide();
        $(".permissionGroup").show();
        this.businessRuleActionData.RecipientsToOrgUnitPermissionGroup =
          selected.value;
      }
    },
    onChangeForSourceSelectForCC: function (selected) {
      this.businessRuleActionData.RecipientsCCOrganizationalUnit = selected.key;
      if (selected.key == "1") {
        this.sourceSelect = "User";
        this.businessRuleActionData.RecipientsCCOrgUnitUser = selected.value;
        $(".user2").show();
        $(".userGroup2").hide();
        $(".permissionGroup2").hide();
      } else if (selected.key == "2") {
        this.businessRuleActionData.RecipientsCCOrgUnitUserGroup =
          selected.value;
        this.sourceSelect = "UserGorup";
        $(".user2").hide();
        $(".userGroup2").show();
        $(".permissionGroup2").hide();
      } else if (selected.key == "3") {
        this.businessRuleActionData.RecipientsCCOrgUnitPermissionGroup =
          selected.value;
        this.sourceSelect = "PermissionGroup";
        $(".user2").hide();
        $(".userGroup2").hide();
        $(".permissionGroup2").show();
      }
    },
    onChangeForSourceSelectForBCC: function (selected) {
      this.businessRuleActionData.RecipientsBCCOrganizationalUnit =
        selected.key;
      if (selected.key == "1") {
        this.businessRuleActionData.RecipientsBCCOrgUnitUser;
        this.sourceSelect = "User";
        $(".user3").show();
        $(".userGroup3").hide();
        $(".permissionGroup3").hide();
      } else if (selected.key == "2") {
        this.businessRuleActionData.RecipientsBCCOrgUnitUserGroup;
        this.sourceSelect = "UserGorup";
        $(".user3").hide();
        $(".userGroup3").show();
        $(".permissionGroup3").hide();
      } else if (selected.key == "3") {
        this.businessRuleActionData.RecipientsBCCOrgUnitPermissionGroup;
        this.sourceSelect = "PermissionGroup";
        $(".user3").hide();
        $(".userGroup3").hide();
        $(".permissionGroup3").show();
      }
    },
    onChangeForSourceSelectForSms: function (selected) {
      this.businessRuleActionData.RecipientsToOrganizationalUnit = selected.key;
      if (selected.key == "1") {
        this.sourceSelect = "User";
        $(".user").show();
        $(".userGroup").hide();
        $(".permissionGroup").hide();
        this.businessRuleActionData.RecipientsToOrgUnitUser = selected.value;
      } else if (selected.key == "2") {
        this.sourceSelect = "UserGorup";
        $(".user").hide();
        $(".userGroup").show();
        $(".permissionGroup").hide();
        selected.value;
      } else if (selected.key == "3") {
        this.sourceSelect = "PermissionGroup";
        $(".user").hide();
        $(".userGroup").hide();
        $(".permissionGroup").show();
        this.businessRuleActionData.RecipientsToOrgUnitPermissionGroup =
          selected.value;
      }
    },
    onChangeForSourceSelect4: function (selected) {
      if (selected.key == "1") {
        this.sourceSelect = "User";
        $(".userSms").show();
        $(".userGroupSms").hide();
        $(".permissionGroupSms").hide();
      } else if (selected.key == "2") {
        this.sourceSelect = "UserGorup";
        $(".userSms").hide();
        $(".userGroupSms").show();
        $(".permissionGroupSms").hide();
      } else if (selected.key == "3") {
        this.sourceSelect = "PermissionGroup";
        $(".userSms").hide();
        $(".userGroupSms").hide();
        $(".permissionGroupSms").show();
      }
    },
    onChangeForSourceSelect5: function (selected) {
      if (selected.key == "1") {
        this.sourceSelect = "User";
        $(".user5").show();
        $(".userGroup5").hide();
        $(".permissionGroup5").hide();
      } else if (selected.key == "2") {
        this.sourceSelect = "UserGorup";
        $(".user5").hide();
        $(".userGroup5").show();
        $(".permissionGroup5").hide();
      } else if (selected.key == "3") {
        this.sourceSelect = "PermissionGroup";
        $(".user5").hide();
        $(".userGroup5").hide();
        $(".permissionGroup5").show();
      }
    },
    onChangeForSourceSelect6: function (selected) {
      if (selected.key == "1") {
        this.sourceSelect = "User";
        $(".user6").show();
        $(".userGroup6").hide();
        $(".permissionGroup6").hide();
      } else if (selected.key == "2") {
        this.sourceSelect = "UserGorup";
        $(".user6").hide();
        $(".userGroup6").show();
        $(".permissionGroup6").hide();
      } else if (selected.key == "3") {
        this.sourceSelect = "PermissionGroup";
        $(".user6").hide();
        $(".userGroup6").hide();
        $(".permissionGroup6").show();
      }
    },
    onChangeForUser: function (ids) {
      this.userList = ids;
    },
    onChangeForUserTO: function (ids) {
      this.businessRuleActionData.RecipientsToOrgUnitUser = ids;
      this.businessRuleActionData.RecipientsTo = ids;
    },
    onChangeForUserTONames: function (name) {
      this.businessRuleActionData.RecipientsToName = name;
    },
    onChangeForUserCC: function (ids) {
      this.businessRuleActionData.RecipientsCCOrgUnitUser = ids;
      this.businessRuleActionData.RecipientsCC = ids;
    },
    onChangeForUserCCNames: function (names) {
      this.businessRuleActionData.RecipientsCCName = names;
    },
    onChangeForUserBCC: function (ids) {
      this.businessRuleActionData.RecipientsBCCOrgUnitUser = ids;
      this.businessRuleActionData.RecipientsBCC = ids;
    },
    onChangeForUserBCCNames: function (names) {
      this.businessRuleActionData.RecipientsBCCName = names;
    },
    onChangeForUserSms: function (ids) {
      this.businessRuleActionData.RecipientsToOrgUnitUser = ids;
      this.businessRuleActionData.RecipientsTo = ids;
    },
    onChangeForUserSmsNames: function (name) {
      this.businessRuleActionData.RecipientsToName = name;
    },
    onChangeForUserGroup: function (ids) {
      this.businessRuleActionData.RecipientsTo = ids;
      this.businessRuleActionData.recipientsToOrgUnitUserGroup = ids;
    },
    onChangeForUserGroupTO: function (ids) {
      this.businessRuleActionData.RecipientsToOrgUnitUserGroup = ids;
      this.businessRuleActionData.RecipientsTo = ids;
    },
    onChangeForUserGroupTONames: function (names) {
      this.businessRuleActionData.RecipientsToName = names;
    },
    onChangeForUserGroupCC: function (ids) {
      this.businessRuleActionData.RecipientsCCOrgUnitUserGroup = ids;
      this.businessRuleActionData.RecipientsCC = ids;
    },
    onChangeForUserGroupCCNames: function (names) {
      this.businessRuleActionData.RecipientsCCName = names;
    },
    onChangeForUserGroupBCC: function (ids) {
      this.businessRuleActionData.RecipientsBCCOrgUnitUserGroup = ids;
      this.businessRuleActionData.RecipientsBCC = ids;
    },
    onChangeForUserGroupBCCNames: function (names) {
      this.businessRuleActionData.RecipientsBCCName = names;
    },
    onChangeForUserGroupSms: function (ids) {
      this.businessRuleActionData.RecipientsToOrgUnitUserGroup = ids;
      this.businessRuleActionData.RecipientsTo = ids;
    },
    onChangeForUserGroupSmsNames: function (names) {
      this.businessRuleActionData.RecipientsToName = names;
    },
    onChangeForPermissionGroup: function (ids) {
      this.permissionGroupList = ids;
    },
    onChangeForPermissionGroupTO: function (ids) {
      this.businessRuleActionData.RecipientsToOrgUnitPermissionGroup = ids;
      this.businessRuleActionData.RecipientsTo = ids;
    },
    onChangeForPermissionGroupTONames: function (names) {
      this.businessRuleActionData.RecipientsToName = names;
    },
    onChangeForPermissionGroupCC: function (ids) {
      this.businessRuleActionData.RecipientsCCOrgUnitPermissionGroup = ids;
      this.businessRuleActionData.RecipientsCC = ids;
    },
    onChangeForPermissionGroupCCNames: function (names) {
      this.businessRuleActionData.RecipientsCCName = names;
    },
    onChangeForPermissionGroupBCC: function (ids) {
      this.businessRuleActionData.RecipientsBCCOrgUnitPermissionGroup = ids;
      this.businessRuleActionData.RecipientsBCC = ids;
    },
    onChangeForPermissionGroupBCCNames: function (names) {
      this.businessRuleActionData.RecipientsBCCName = names;
    },
    onChangeForPermissionGroupSms: function (ids) {
      this.businessRuleActionData.RecipientsToOrgUnitPermissionGroup = ids;
      this.businessRuleActionData.RecipientsTo = ids;
    },
    onChangeForPermissionGroupSmsNames: function (names) {
      this.businessRuleActionData.RecipientsToName = names;
    },
    onChangeForSourceFromFieldTO: function (ids) {
      this.businessRuleActionData.RecipientsToFromField = ids;
      this.businessRuleActionData.RecipientsTo = ids;
    },
    onChangeForSourceFromFieldTONames: function (names) {
      this.businessRuleActionData.RecipientsToName = names;
      this.businessRuleActionData.RecipientsToLookUpFieldsNames = names;
    },
    onChangeForSourceFromFieldCC: function (ids) {
      this.businessRuleActionData.RecipientsCCFromField = ids;
      this.businessRuleActionData.RecipientsCC = ids;
    },
    onChangeForSourceFromFieldCCNames: function (names) {
      this.businessRuleActionData.RecipientsCCName = names;
    },
    onChangeForSourceFromFieldBCC: function (ids) {
      this.businessRuleActionData.RecipientsBCCFromField = ids;
      this.businessRuleActionData.RecipientsBCC = ids;
    },
    onChangeForSourceFromFieldBCCNames: function (names) {
      this.businessRuleActionData.RecipientsBCCName = names;
    },
    onChangeForSourceFromFieldSms: function (ids) {
      this.businessRuleActionData.RecipientsToFromField = ids;
      this.businessRuleActionData.RecipientsTo = ids;
    },
    onChangeForSourceFromFieldSmsNames: function (names) {
      this.businessRuleActionData.RecipientsToName = names;
    },
    onChangeForRelatedCC: function (selected) {
      this.businessRuleActionData.recipientsCC = selected;
      this.businessRuleActionData.recipientsCCFromRelationTo = selected;
    },
    onChangeForRelatedCCNames: function (names) {
      this.businessRuleActionData.recipientsCCName = names;
    },
    onChangeForRelatedBCC: function (selected) {
      this.businessRuleActionData.recipientsBCC = selected;
      this.businessRuleActionData.recipientsBCCFromRelationTo = selected;
    },
    onChangeForRelatedBCCNames: function (names) {
      this.businessRuleActionData.recipientsBCCName = names;
    },
    getCustomObjectDetail() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-CustomObjectGet?id={0}",
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.businessActionObject = response.data;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onChangeForNotificationType: function (selected) {
      this.businessRuleActionData.NotifierTypeId = selected.key;
      this.selectsReset = !this.selectsReset;
      this.notificationType = selected.key;
      $(".emailNotTab").show();
      $(".smsNotTab").hide();
      $(".systemNotTab").hide();
      if (selected.key == "2") {
        this.fieldsType = "Phone";
        $(".emailNotTab").hide();
        $(".smsNotTab").show();
        $(".systemNotTab").hide();
        $(".ToTab").show();
        $(".systemNotificationTab").hide();
        $(".email").hide();
        $(".ccTab").hide();
        $(".bccTab").hide();
        $(".sendByEmail").hide();
      } else if (selected.key == "3") {
        this.fieldsType = "OrganizationalUnit|FormulaOrganizationalUnit";
        this.businessRuleActionData.RecipientsToInfoType = 2;
        $(".emailNotTab").hide();
        $(".smsNotTab").hide();
        $(".systemNotTab").show();
        $(".ToTab").hide();
        $(".systemNotificationTab").show();
        $(".email").show();
        $(".notificationPlugin").hide();
        $(".attachment").hide();
        $(".ccTab").hide();
        $(".bccTab").hide();
        $(".sendByEmail").hide();
      } else {
        //email
        this.fieldsType = "Email|FormulaOrganizationalUnit";
        $(".ToTab").show();
        $(".systemNotificationTab").hide();
        $(".email").show();
        $(".ccTab").show();
        $(".bccTab").show();
        $(".sendByEmail").show();
        $(".notificationPlugin").show();
        $(".attachment").show();
      }
    },
    onChangeForNotifierSettings: function (selected) {
      this.businessRuleActionData.notifierSettingPublicId = selected.key;
      this.businessRuleActionData.notifierSettingName = selected.value;
    },
    onChangeForMessageTemplate: function (selected) {
      this.businessRuleActionData.MessageTemplatePublicId = selected.key;
      this.businessRuleActionData.MessageTemplateName = selected.value;
    },
    onChangeFormTemplatesForBusinessRule: function (ids) {
      this.businessRuleActionData.AttachementFormTemplatePublicIds = ids;
    },
    onChangeFormTemplateNamesForBusinessRule: function (names) {
      this.businessRuleActionData.AttachementFormTemplateNames = names;
    },
    onChangeForFieldsByTypeFile: function (ids) {
      this.businessRuleActionData.AttachmentPublicIds = ids;
    },
    onChangeForFieldsByTypeFileNames: function (names) {
      this.businessRuleActionData.AttachmentNames = names;
    },
    onClear() {
      this.businessRuleActionData = {
        customObjectPublicId: this.$route.params.customObjectId,
        businessRulePublicId: this.$route.params.businessRuleId,
        name: "",
        formula: "",
        errorText: "",
        isShowMessageInModal: false,
        downloadResponseAsDocument: false,
        isActive: true,
        isSynchAction: false,
      };

      $(".form-businessruleactionsendnotif-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-businessruleactionsendnotif-new");
      form.addClass("was-validated");
      if (
        !this.$root.brActionValidity(form[0]) ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }
      if (this.businessRuleActionData.RecipientsToInfoType == 1) {
        this.businessRuleActionData.RecipientsToName =
          this.businessRuleActionData.RecipientsToText;
        this.businessRuleActionData.RecipientsTo =
          this.businessRuleActionData.RecipientsToText;
        // this.businessRuleActionData.recipientsToText =
        //   this.businessRuleActionData.recipientsTo;
        // this.businessRuleActionData.recipientsToName =
        //   this.businessRuleActionData.recipientsTo;
      }
      if (this.businessRuleActionData.NotifierTypeId == 1) {
        if (this.businessRuleActionData.RecipientsCCInfoType == 1) {
          this.businessRuleActionData.RecipientsCCName =
            this.businessRuleActionData.RecipientsCCText;
          this.businessRuleActionData.RecipientsCC =
            this.businessRuleActionData.RecipientsCCText;
        }
        if (this.businessRuleActionData.RecipientsBCCInfoType == 1) {
          this.businessRuleActionData.RecipientsBCCName =
            this.businessRuleActionData.RecipientsBCCText;
          this.businessRuleActionData.RecipientsBCC =
            this.businessRuleActionData.RecipientsBCCText;
        }
      }
      if (this.businessRuleActionData.RecipientsToInfoType == 3) {
        var recipientsToField =
          this.businessRuleActionData.RecipientsTo.split("|");
        var recipientsToLookUpFieldsNames =
          this.businessRuleActionData.RecipientsToName.split("|");
        recipientsToField.forEach((v, i) => {
          this.selectedFieldTO.push({
            key: v,
            value: recipientsToLookUpFieldsNames[i],
          });
        });
        this.onChangeForSourceFromFieldTO(
          this.businessRuleActionData.RecipientsTo
        );
        this.onChangeForSourceFromFieldTONames(
          this.businessRuleActionData.RecipientsToLookUpFieldsNames
        );
      }
      this.errors = [];
      this.$prodGatewayAxios
        .post("/Brs-BusinessRuleActionNewActionSendNotification", {
          ...this.businessRuleActionData,
        })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push(
                "/CustomObject/BusinessRuleActions/" +
                  this.$route.params.customObjectId +
                  "&businessRuleId=" +
                  this.$route.params.businessRuleId
              );
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.getCustomObjectDetail();
    this.businessRuleTriggerTypeId = localStorage.getItem(
      "businessRuleTriggerTypeId"
    );
    this.businessRuleActionType = localStorage.getItem(
      "businessRuleActionType"
    );
  },
  watch: {
    "$route.params.customObjectId"() {
      this.businessRuleActionData.customObjectPublicId =
        this.$route.params.customObjectId;
    },
  },
};
</script>
